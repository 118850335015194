import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const SupportPage = ({data}) => {
    console.log(data)
    return (

        <Layout pageClass='support-page'>
        <SEO title="Support Page" />
            <main className='main'>
                <div className='typography inner'>
                    <h1>Support</h1>

                    <div className="support-holder">
                        <div className="support">
                            <div className="img">
                                <img src={data.allImageSharp.nodes[2].fixed.src} alt="Spare Parts" />

                                
                                
                            </div>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36.09" height="36.09" viewBox="0 0 36.09 36.09">
                                <path id="Icon_open-cog" data-name="Icon open-cog" d="M15.75,0,13.5,5.355a6.414,6.414,0,0,0-1.26.585L6.885,3.69,3.645,6.93l2.25,5.355c-.225.45-.405.81-.585,1.26L-.045,15.8v4.5l5.355,2.25c.18.45.36.81.585,1.26L3.645,29.16l3.24,3.24,5.355-2.25c.4.18.81.405,1.26.585l2.25,5.355h4.5l2.25-5.355c.405-.18.855-.36,1.26-.585l5.355,2.25,3.24-3.24L30.1,23.805c.18-.405.405-.855.585-1.26l5.355-2.25V15.8l-5.355-2.25a7.893,7.893,0,0,0-.585-1.26l2.25-5.355-3.24-3.24L23.76,5.94c-.405-.18-.855-.405-1.26-.585L20.25,0h-4.5ZM18,11.25A6.75,6.75,0,1,1,11.25,18,6.741,6.741,0,0,1,18,11.25Z" transform="translate(0.045)"/>
                                </svg>
                            </div>
                            <div className="text-holder">
                                <h3>Spare Parts</h3>
                            
                                <p>We stock a wide range of spare parts for all printers and coders and offer on-site repairs, installation, set-up services and regular  maintenance contracts for your equipment tailor made to suit your requirements and operation.</p>
                            </div>
                        </div>
                        <div className="support">
                            <div className="img">
                                <img src={data.allImageSharp.nodes[1].fixed.src} alt="Custom Installation" />
                                
                            </div>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36.002" height="36.004" viewBox="0 0 36.002 36.004">
                                <path id="Icon_awesome-tools" data-name="Icon awesome-tools" d="M35.234,27.823,27,19.589a5.221,5.221,0,0,0-6-.977l-7.5-7.5V6.75L4.5,0,0,4.5l6.75,9h4.366l7.5,7.5a5.232,5.232,0,0,0,.977,6l8.234,8.234a2.614,2.614,0,0,0,3.705,0l3.705-3.705a2.627,2.627,0,0,0,0-3.705Zm-11.911-12A7.4,7.4,0,0,1,28.589,18l1.364,1.364a9.953,9.953,0,0,0,3.08-2.074A10.112,10.112,0,0,0,35.7,7.678a.842.842,0,0,0-1.413-.387l-5.231,5.231-4.774-.795-.795-4.774,5.231-5.231a.849.849,0,0,0-.4-1.42,10.129,10.129,0,0,0-9.6,2.665,9.964,9.964,0,0,0-2.9,7.284l5.773,5.773a7.66,7.66,0,0,1,1.737-.2Zm-7.305,5.766L12.03,17.6,1.315,28.322a4.5,4.5,0,0,0,6.363,6.363l8.691-8.691a7.551,7.551,0,0,1-.352-4.409ZM4.5,33.188A1.688,1.688,0,1,1,6.188,31.5,1.692,1.692,0,0,1,4.5,33.188Z" transform="translate(0.004)"/>
                                </svg>
                            </div>
                            
                            <div className="text-holder">
                                <h3>Custom Installation</h3>
                            
                                <p>We specialize in custom installation of barcode printers, software, scanners, industrial coders, ink jet coders, etc into your workplace.  Our experienced and skilled technical staff can integrate your printers and coders with your auxiliary office/work station/manufacturing equipment to ensure a turn key solution that suits your needs.</p>
                            </div>
                        </div>
                        <div className="support">
                            <div className="img">
                                <img src={data.allImageSharp.nodes[0].fixed.src} alt="Printer Set-Up" />
                               
                            </div>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30.849" height="26.992" viewBox="0 0 30.849 26.992">
                                <path id="Icon_metro-printer" data-name="Icon metro-printer" d="M10.283,3.856H25.707V7.712H10.283V3.856ZM31.491,9.64H4.5a1.934,1.934,0,0,0-1.928,1.928v9.64A1.934,1.934,0,0,0,4.5,23.136h5.784v7.712H25.707V23.136h5.784a1.934,1.934,0,0,0,1.928-1.928v-9.64A1.934,1.934,0,0,0,31.491,9.64ZM6.427,15.424A1.928,1.928,0,1,1,8.355,13.5a1.928,1.928,0,0,1-1.928,1.928Zm17.352,13.5H12.211V19.28H23.779Z" transform="translate(-2.571 -3.856)"/>
                                </svg>
                            </div>
                            <div className="text-holder">
                                <h3>Printer Set-Up</h3>
                            
                                <p>Need to have your desktop printer or coder set up? Our technical support personal can perform on-site printing and coding equipment s et-up and installation with your preferred labeling software programs and label formats.</p>                            
                            </div>
                        </div>
                        

                           
                        <div className="support">
                            <div className="img">
                                <img src={data.allImageSharp.nodes[3].fixed.src} alt="Preventative Maintenance Contracts" />
                               
                            </div>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34.56" height="23.04" viewBox="0 0 34.56 23.04">
                                <path id="Icon_map-general-contractor" data-name="Icon map-general-contractor" d="M25.548,10.824a.326.326,0,0,1-.348-.3V6.079a.283.283,0,0,0-.276-.319H11.075a.283.283,0,0,0-.275.319v4.45c0,.176-.194.271-.374.271H1.043a.367.367,0,0,0-.323.366v4.946c0,.174.146.448.323.448h15.11a.535.535,0,0,0,.407-.448V15.057c0-.176.066-.657.245-.657H19.2c.179,0,.244.482.244.657v1.055a.535.535,0,0,0,.405.448H34.955c.18,0,.325-.274.325-.448V11.166a.366.366,0,0,0-.325-.366H25.573ZM13.027,10.8c-.179,0-.067-.1-.067-.271V8c0-.176-.112-.08.067-.08h9.946c.179,0,.067-.1.067.08v2.529c0,.175.112.271-.067.271H13.027ZM19.48,21.393c0,.174-.105.207-.284.207H16.8c-.179,0-.245.077-.245-.1V17.947c0-.174-.228-.667-.406-.667H1.043c-.177,0-.323.493-.323.667V28.48a.324.324,0,0,0,.323.32H34.955a.323.323,0,0,0,.325-.319V17.947c0-.174-.145-.667-.325-.667H19.845c-.178,0-.405.493-.405.667V21.5l.04-.11Z" transform="translate(-0.72 -5.76)"/>
                                </svg>

                            </div>
                            <div className="text-holder">
                                <h3>Preventative Maintenance Contracts</h3>
                            
                                <p>We can offer you a tailor made maintenance contract to suit your machine, environment and requirements to ensure your machine operates at maximum efficiency and minimum downtime saving you frustration, time and money!</p>
                            </div>
                        </div>


                    </div>
                </div>
            </main>  
        </Layout>

    )
}
export const query = graphql`
  query {
    allImageSharp(filter: {fluid: {originalName: {in: ["board-e1383100802191.jpg", "MAINTENANCE.jpg", "SEUP.jpg", "X4JET.png"]}}}) {
        nodes {
            
          fixed(width: 450, height: 300) {
            src
          }
        }
      }
  }
`

export default SupportPage